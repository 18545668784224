import { z } from 'zod';
import { createPaginatedResponseSchema } from '../context/api/utils/paginated-response';
import { ruleSchema } from './shared-schema';
import { orderEnum } from './pagination/pagination-schema';
import { alertStatusKeys } from 'domain/status/alert-status';

const AVAILABLE_SORTABLE_FIELDS = ['Created', 'AlertId'] as const;

const alertStatus = z.enum(alertStatusKeys);
export const customerAlertSortableFields = z.enum(AVAILABLE_SORTABLE_FIELDS);

const customerAlertQuerySchema = z.object({
    page: z.number().nullable(),
    status: alertStatus.array(),
    sortField: customerAlertSortableFields.nullable(),
    sortOrder: orderEnum.nullable(),
});

const customerAlertchema = z.object({
    alertId: z.number(),
    assigned: z.object({ firstName: z.string(), lastName: z.string(), userId: z.string() }).array(),
    created: z.string().datetime(),
    customerId: z.string(),
    rule: ruleSchema,
    status: alertStatus,
});

const customerAlertPaginatedResponse = createPaginatedResponseSchema(customerAlertchema);

export type CustomerAlertResponse = z.infer<typeof customerAlertPaginatedResponse>;
export type CustomerAlertParams = z.infer<typeof customerAlertQuerySchema>;
export type CustomerAlert = z.infer<typeof customerAlertchema>;
export type CustomerAlertSortField = z.infer<typeof customerAlertSortableFields>;
