import { z } from 'zod';
import { orderEnum } from './../pagination/pagination-schema';
import { ruleSchema } from './../shared-schema';
import { alertStatusKeys } from 'domain/status/alert-status';
import { createPaginatedResponseSchema } from '../../context/api/utils/paginated-response';

const SORTABLE_ALERT_FIELDS = ['AlertId', 'Created'] as const;
export const sortableAlertfieldEnum = z.enum(SORTABLE_ALERT_FIELDS);

const caseAlertQuerySchema = z.object({
    sortField: sortableAlertfieldEnum.nullable(),
    sortOrder: orderEnum.nullable(),
    page: z.number().nullable(),
    search: z.string(),
});

const caseAlertSchema = z.object({
    alertId: z.number(),
    customerId: z.string(),
    created: z.date(),
    rule: ruleSchema,
    status: z.enum(alertStatusKeys),
});

const caseAlertPaginatedResponse = createPaginatedResponseSchema(caseAlertSchema);

export type CaseAlert = z.infer<typeof caseAlertSchema>;
export type CaseAlertQueryParams = z.infer<typeof caseAlertQuerySchema>;
export type CaseAlertResponse = z.infer<typeof caseAlertPaginatedResponse>;
export type CaseAlertSortField = z.infer<typeof sortableAlertfieldEnum>;
