export interface IUploadFileResult<T> {
    isError: boolean;
    isSuccess: boolean;
}

export class UploadSuccessFileResult<T> implements IUploadFileResult<T> {
    isSuccess: boolean = true;
    isError: boolean = false;
    data: T;

    constructor(data: T){
        this.data = data;
    }
}

export class UploadErrorFileResult<T> implements IUploadFileResult<T> {
    isSuccess: boolean = false;
    isError: boolean = true;
    message: string;

    constructor(message: string = undefined){
        this.message = message === undefined ? 'An unknown error has occurred while uploading file.' : '';
    }
}