import { z } from 'zod';
import { legalFormsEnum } from 'shared/schema/legal-form-schema';

const AVAILABLE_VALUE_TYPES = ['Date', 'String', 'Number', 'Boolean', 'NumberRange', 'DateRange'] as const;
export const kycValueType = z.enum(AVAILABLE_VALUE_TYPES);

const AVAILABLE_CUSTOMER_TYPES = ['Person', 'Organization'] as const;
export const customerType = z.enum(AVAILABLE_CUSTOMER_TYPES);

const customerInformationSchema = z.object({
    from: z.string().nullable(),
    to: z.string().nullable(),
    name: z.string(),
    type: kycValueType,
    value: z.string(),
});

const customerDetailsSchema = z.object({
    customerId: z.string(),
    customerType: customerType,
    firstName: z.string(),
    lastName: z.string(),
    name: z.string(),
    information: z.array(customerInformationSchema),
    kyc: z.array(customerInformationSchema),
    identification: z.string().nullable(),
    birthdate: z.string().nullable(),
    legalForm: legalFormsEnum,
});

const customerStatisticsSchema = z.object({
    alerts: z.number(),
    unassignedAlerts: z.number(),
    cases: z.number(),
    falsePositives: z.number(),
});

export type CustomerStatistics = z.infer<typeof customerStatisticsSchema>;
export type CustomerDetailsMapped = z.infer<typeof customerDetailsSchema>;
export type CustomerDetailsResponse = z.infer<typeof customerDetailsSchema>;

export type CustomerKyc = z.infer<typeof customerInformationSchema>;
export type CustomerKycValueType = z.infer<typeof kycValueType>;
