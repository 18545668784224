import { z } from 'zod';
import { createPaginatedResponseSchema } from '../context/api/utils/paginated-response';
import { orderEnum } from './pagination/pagination-schema';

const AVAILABLE_SORTABLE_FIELDS = ['Created'] as const;
const AVAILABLE_STATUSES = ['Investigating', 'Resolved', 'Reporting', 'Reported'] as const;

const caseStatus = z.enum(AVAILABLE_STATUSES);
export const customerCaseSortableFields = z.enum(AVAILABLE_SORTABLE_FIELDS);

const customerCaseQuerySchema = z.object({
    page: z.number().nullable(),
    sortField: customerCaseSortableFields.nullable(),
    sortOrder: orderEnum.nullable(),
    status: caseStatus.array()
});

const customerCaseSchema = z.object({
    assigned: z.object({ firstName: z.string(), lastName: z.string(), userId: z.string() }).array(),
    caseId: z.number(),
    created: z.string().datetime(),
    customerId: z.string(),
    name: z.string(),
    status: caseStatus,
});

const CustomerCasePaginatedResponse = createPaginatedResponseSchema(customerCaseSchema);

export type CustomerCaseResponse = z.infer<typeof CustomerCasePaginatedResponse>;
export type CustomerCaseParams = z.infer<typeof customerCaseQuerySchema>;
export type CustomerCase = z.infer<typeof customerCaseSchema>;
export type CustomerCaseSortField = z.infer<typeof customerCaseSortableFields>;
