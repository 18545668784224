import { z } from 'zod';

const LEGAL_FORMS = [
    'Unknown',
    'Other',
    'EconomicAssociation',
    'LimitedCompany',
    'SoleProprietorship',
    'Subdivision',
    'GeneralPartnership',
    'NonProfitAssociation',
    'LimitedPartnership',
    'GovernmentAuthority',
    'Foundation',
    'ForeignCompany',
] as const;

export const legalFormsEnum = z.enum(LEGAL_FORMS);
export type LegalForm = z.infer<typeof legalFormsEnum>;